<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1200"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <div style="display: flex;align-items: flex-start">
        <div style="flex:1">
          <a-form-model-item label="商品名称" v-bind="formItemLayout" prop="goodsTitle">
            <a-textarea v-model="form.goodsTitle" v-decorator="['goodsTitle']" style="min-height:80px;"
                        placeholder="请输入商品名称"/>
          </a-form-model-item>
          <a-form-model-item label="次级名称" v-bind="formItemLayout" prop="subTitle">
            <a-input v-model="form.subTitle" v-decorator="['subTitle']"
                     placeholder="请输入次级名称"/>
          </a-form-model-item>
          <a-form-model-item
              label="商品分类"
              prop="cateId"
              v-bind="formItemLayout"
          >
            <div style="display: flex;align-items: center">
            <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']"
                      placeholder="请选择商品分类"></a-select>
            <a-button type="primary" :loading="confirmLoading" @click="importFromPdd">
              从拼多多导入
            </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="所属平台" v-bind="formItemLayout" prop="hotTop">
            <a-radio-group v-model="form.platform" :options="dict.goodsPlatform"></a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="是否有效" v-bind="formItemLayout" prop="status">
            <a-radio-group v-model="form.status" v-decorator="['status',
                   {initialValue: 1}]">
              <a-radio :value="1">有效</a-radio>
              <a-radio :value="0">无效</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div class="flex" style="margin-left:80px;">
            <a-form-model-item label="是否推荐" v-bind=" formItemLayout" prop="isTop" style="flex:1">
              <a-radio-group v-model="form.isTop" v-decorator="['isTop',
                   {initialValue: 0}]" style="margin-left:10px">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="是否热门" v-bind="formItemLayout" prop="hotTop" style="flex:1">
              <a-radio-group v-model="form.hotTop" v-decorator="['hotTop',
                   {initialValue: 0}]" style="margin-left:10px">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </div>
          <a-form-model-item label="价格" v-bind="formItemLayout" prop="price">
            <a-input-number :step="0.1" v-model="form.price" v-decorator="['price']"/>
          </a-form-model-item>
          <div class="flex" style="margin-left:80px;">
            <a-form-model-item label="原价格" v-bind="formItemLayout" prop="orgPrice" style="flex:1">
              <a-input-number :step="0.1" v-model="form.orgPrice" v-decorator="['orgPrice']"
                              style="width: 120px!important;margin-left1:5px"/>
            </a-form-model-item>
            <a-form-model-item label="优惠券" v-bind="formItemLayout" prop="couponPrice" style="margin-left:-40px;flex:1;">
              <a-input-number :step="0.1" v-model="form.couponPrice" v-decorator="['couponPrice']"
                              style="width: 120px!important;margin-left:10px"/>
            </a-form-model-item>
          </div>
          <div class="flex" style="margin-left:80px;">
            <a-form-model-item label="优惠日期" v-bind="formItemLayout" prop="validFrom">
              <a-date-picker v-model="form.validFrom" v-decorator="['validFrom']"/>
            </a-form-model-item>
            <a-form-model-item label="至" v-bind="formItemLayout" prop="validTo" style="margin-left:-50px;width:250px;">
              <a-date-picker v-model="form.validTo" v-decorator="['validTo']"/>
            </a-form-model-item>
          </div>
          <a-form-model-item label="销量" v-bind="formItemLayout" prop="salesTip">
            <a-input v-model="form.salesTip" v-decorator="['salesTip']"/>
          </a-form-model-item>


        </div>

        <!---->
        <div style="flex:1;">
          <a-form-model-item label="商品详情" v-bind="formItemLayout2" prop="remark">
            <tiny-mce-editor v-if="visible" ref="editor" v-model="form.goodsDesc"
                             style="width: 100%;"></tiny-mce-editor>
          </a-form-model-item>
        </div>
      </div>
      <a-form-model-item ref="doorHeader" label="商品图片" v-bind="formItemLayout2">
        <a-textarea v-model="form.images" v-decorator="['images']" style="min-height:30px;"
                    placeholder="可以直接输入图片路径（多个逗号分隔）"/>
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="true"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div>
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item label="标签" v-bind="formItemLayout2" prop="topTag">
        <a-input v-model="form.topTag" v-decorator="['topTag']"/>
      </a-form-model-item>
      <a-form-model-item label="产品链接" v-bind="formItemLayout2" prop="openMode">
        <a-textarea v-model="form.openMode" v-decorator="['openMode']"/>
      </a-form-model-item>
      <a-form-model-item label="掌柜推荐" v-bind="formItemLayout2" prop="adminDesc">
        <!--        <a-textarea v-model="form.adminDesc" v-decorator="['adminDesc']"/>-->
        <tiny-mce-editor v-if="visible" ref="editor2" v-model="form.adminDesc"
                         style="width: 100%;height:200px;overflow-y: auto"></tiny-mce-editor>
      </a-form-model-item>
      <a-form-model-item label="备注说明" v-bind="formItemLayout2" prop="remark">
        <a-input v-model="form.remark" v-decorator="['remark']"/>
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <div class="modal-btn flex">
        <span class="flex_1">&nbsp;</span>
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          确定
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="visible=false">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {getBase64OfPreview} from '@/utils/common.js';
import TinyMceEditor from "@/components/Editor/TinyMceEditor";
import {DICT} from "@/utils/dict";
import {MessageBox} from 'element-ui';

import RangeDate from '@/components/datetime/RangeDate'
import moment from "moment";

export default {
  name: "ModifyGoodsModal",
  components: {TinyMceEditor, RangeDate},
  data() {
    return {
      dict: DICT,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      formItemLayout2: {
        labelCol: {span: 3},
        wrapperCol: {span: 20},
      },
      orgCateList: [],
      mode: 'edit',
      title: "",
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      form: {},
      imageList: [],
      previewImage: "",
      rules: {
        cateName: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
    cateList() {
      let cateType = 5;//类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类）
      return this.orgCateList.filter(v => v.type === cateType).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },
  created() {
    this.$postJson('/category/cateList', {pageSize: 100, type: 5,status:1}).then(res => {
      this.orgCateList = ((res.data && res.data.records) || [])
    })
  },
  methods: {
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview && !file.thumbUrl) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    async showModal(record, mode) {
      const that = this;

      this.visible = true;
      this.mode = mode || 'new'

      let array = [];
      if (this.mode == 'new') {
        this.title = "增加商品";
        this.form = {
          isTop: 0,
          status: 1,
          platform: 2,
          hotTop: 0
        };
      } else {
        this.title = "修改商品：" + record.goodsTitle;
        let res = await this.$get('goods/info/' + record.id)
        this.form = {
          ...res.data,
        };

        debugger
        array = this.convertFileList(res.data?.images)
      }
      this.imageList = array;
    },
    convertFileList(list = []) {
      const that = this;
      if (!list) {
        return [];
      }
      if (typeof list === 'string') {
        list = list.split(/[,，、\r\n;]/g);
      }
      let array = [];
      list.forEach((v) => {
        if (!v) return;
        let path = that.$helper.getFullImgPath(v);
        array.push({
          type: "image/png",
          name: v,
          uid: v,
          status: "done",
          thumbUrl: path,
          response: {data: path, code: 0},
        });
      });

      return array;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.images = this.imageList.map((item) => {
            return item.response.data;
          }).join(',');
          let params = {
            ...this.form
          }


          if (this.form.validFrom) {
            if (typeof this.form.validFrom === 'string') {
              if (this.form.validFrom.indexOf(' ') < 0) {
                params.validFrom = this.form.validFrom + ' 00:00:00'
              }
            } else {
              params.validFrom = this.form.validFrom.format('YYYY-MM-DD') + ' 00:00:00'
            }
          }
          if (this.form.validTo) {
            if (typeof this.form.validTo === 'string') {
              if (this.form.validTo.indexOf(' ') < 0) {
                params.validTo = this.form.validTo + ' 00:00:00'
              }
            } else {
              params.validTo = this.form.validTo.format('YYYY-MM-DD') + ' 00:00:00'
            }
          }
          this.$postJson("goods/save", params).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
    importFromPdd() {
      const that = this;

      MessageBox.prompt('请输入拼多多商品Sign', '从拼多多导入商品', {
        inputValue: this.form.goodsSign,
        inputPlaceholder: '输入商品Sign',
      }).then(({value}) => {
        if (value !== '') {
          that.showLoading = true;
          that.$get("goods/pddInfo", {goodsSign: value}).then((res) => {
            that.$message.success("导入成功成功");

            let json = JSON.parse(res.data?.detailJson)
            if (!json?.goods_detail_response) {
              return;
            }
            this.form.platform = 2;//平台类型（1--自由商品，2--拼多多，3--京东，4--唯品会，5--淘宝...)
            this.form.openMode = res?.data?.openMode || '';
            let detail = json.goods_detail_response.goods_details[0];
            this.form.goodsTitle = detail.goods_name
            this.imageList = this.convertFileList(detail.goods_gallery_urls || []);
            this.form.images = (detail.goods_gallery_urls || []).join(",");
            this.form.masterImage = detail.goods_image_url || detail.goods_gallery_urls[0];
            this.form.topTag = (detail.unified_tags || []).join(',')
            this.form.orgPrice = (detail.min_group_price || 0) / 100.0;
            this.form.couponPrice = (detail.coupon_discount || 0) / 100.0;
            this.form.price = this.form.orgPrice - this.form.couponPrice;
            // this.form.subTitle = detail.goods_name
            this.form.salesTip = detail.sales_tip;
            if (detail.coupon_start_time) {
              this.form.validFrom = moment(detail.coupon_start_time * 1000).format('YYYY-MM-DD');
            }
            if (detail.coupon_end_time) {
              this.form.validTo = moment(detail.coupon_end_time * 1000).format('YYYY-MM-DD');
            }
            this.form.goodsSign = value;

            let desc = ""
            if (detail.goods_desc != detail.goods_name) {
              desc += detail.goods_desc;
            }
            (detail.goods_gallery_urls || []).forEach(v => {
              desc += "<div><img src='" + v + "'/></div>"
            })

            this.form.goodsDesc = desc

          });
        }
      })
    }
  },
};
</script>
<style>
.mce-container iframe {
  height: 400px !important;
}
</style>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
